import React, {Component} from 'react';
import RentRollTable from "../components/RentRollTable"

class Property extends Component {
    constructor(props) {
        super(props)
        this.state = {
            propertyId:props.match.params.propertyId
        }
    }

    componentWillReceiveProps(nextProps, nextContext) {
        console.info("componentWillReceiveProps PropertyID " + nextProps.match.params.propertyId)
        this.setState({
            propertyId: nextProps.match.params.propertyId
        });
    }

    componentDidMount() {
        this.setState({
            propertyId: this.props.match.params.propertyId
        });
    }

    render() {
        return (
            <div className="table-responsive-lg">
                <RentRollTable propertyId={this.state.propertyId}/>
            </div>
        );
    }
}

export default Property;