import React, {Component} from 'react';
import {
    Card, CardImg, CardText, CardBody,
    CardTitle, CardSubtitle, Progress, Alert, NavLink
} from 'reactstrap';
import {axiosInstance} from "./services/axiosservice";
import PropertyService from "./services/PropertyService";
import {NavLink as RRNavLink} from "react-router-dom";
import { withRouter } from 'react-router-dom';
import VacantUnitsInfo from "./components/VacantUnitsInfo";
import Spinner from "reactstrap/es/Spinner";
import {TRACKING_URL} from "./Constants";
import ExpiringLeasesInfo from "./components/ExpiringLeasesInfo";


class PropertyCard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            rentRoll:[],
            totalUnits:0,
            vacantUnits:[],
            preleasedUnits:[],
            pendingRenewUnits:[],
            workOrders:[],
            holdUnits :[]
        }

    }

    loadData(propertyId, requestType) {
        if (propertyId === undefined) {
            return
        }
        console.info("Start loadData data for " + propertyId)
        let url = TRACKING_URL + 'daily?propertyId='+this.props.property.id + '&requestType='+requestType
        axiosInstance.get(url
        ) // JSON File Path
            .then( response => {
                console.info("loadData Got data for " + propertyId + " " + requestType)
                if(requestType === 'rentroll') {
                    this.setState({
                        rentRoll: response.data
                    });
                }
                else if(requestType === 'workorders') {
                    console.info("Work Orders " + response.data)
                    this.setState({
                        workOrders: response.data
                    });
                }
                else if(requestType === 'holdunits') {
                    console.info("Hold Units " + response.data)
                    this.setState({
                        holdUnits: response.data
                    });
                }

            })
            .catch(function (error) {
                console.log(error);
            });
    }

    componentDidMount() {
        this.loadData(this.props.property.id, 'rentroll')
        this.loadData(this.props.property.id, 'workorders')
        this.loadData(this.props.property.id, 'holdunits')
    }

    populateStatsWithRentRoll(rentRoll) {
        const rentRollInfoByDate = rentRoll['dates']

        let propertyService = new PropertyService()
        const sortedColumn = propertyService.getColumnsForRentRoll(rentRollInfoByDate)
        const result = propertyService.getRentRollRowsByUnits(rentRollInfoByDate, sortedColumn)
        /*this.state.vacantUnits = result["vacantUnits"]
        this.state.preleasedUnits = result["preleasedUnits"]
        this.state.totalUnits = result["totalUnits"]
        this.state.pendingRenewUnits = result["pendingRenewUnits"]*/

        /*this.setState({rentRoll:rentRoll, vacantUnits:result["vacantUnits"],
          preleasedUnits:result["preleasedUnits"],
          pendingRenewUnits:result["pendingRenewUnits"],
          totalUnits:result["totalUnits"]
        })*/
        return result
    }

    navigateToProperty(id){
        this.props.history.push('/property/'+id)
    }
    render() {
        //let {id, name, company, description, total, vacant, preleased, pendingRenewUnits} = this.props.property        
        let {rentRoll} = this.state
        let {workOrders} = this.state
        let {holdUnits} = this.state
        if(rentRoll === 'undefined' || rentRoll.length === 0) {
            return (<div>
                <Spinner type="grow" color="info" />
            </div>)
        }

        console.info("Start render data for " + rentRoll)
        console.info("Received workorders " + workOrders.length)
        const property = rentRoll['property']
        const vacant_history = rentRoll['vacant_history']
        let past_due_work_orders = 0
        let due_work_orders = 0
        let makeready_work_orders = 0
        let number_of_hold_units = 0
        if (workOrders !== 'undefined' && workOrders != null && !Array.isArray(workOrders)) {
            past_due_work_orders = workOrders.past_due_work_orders_count
            due_work_orders = workOrders.due_work_orders_count
            makeready_work_orders = workOrders.makeready_work_orders_count
        }
        if (holdUnits !== 'undefined' && holdUnits != null && Array.isArray(holdUnits)) {
            number_of_hold_units = holdUnits.length
        }

        const results = this.populateStatsWithRentRoll(rentRoll)

        let {vacantUnits} = results
        let {preleasedUnits} = results
        let {totalUnits} = results
        let {pendingRenewUnits} = results
        let totalVacantNumber = vacantUnits.length+preleasedUnits.length
        let rentRollInfoByDate = rentRoll['dates']

        let occupancyPercent = Math.trunc(100-(totalVacantNumber/totalUnits)*100)
        let occupancyPreleasedPercent = Math.trunc(100-((totalVacantNumber-preleasedUnits.length)/totalUnits)*100)
        return (
            <div>
                <Card>
                    <NavLink tag={RRNavLink} exact to={"/property/"+this.props.property.id} activeClassName="active">{this.props.property.name}</NavLink>
                    <CardImg top width="100%" src={this.props.property.img} alt="Card image cap"
                             onClick={() => { this.navigateToProperty(this.props.property.id) }}/>

                    <CardBody>
                        <Alert color={occupancyPercent >= 95 ? 'success' : occupancyPercent >= 90 ? 'warning': 'danger'}>
                            <CardTitle>{property["Name"]} : Total Units {totalUnits.toString()}</CardTitle>                            
                            <VacantUnitsInfo occupancy={occupancyPercent} vacantHistory={vacant_history}/>
                            <CardTitle>Preleased Occupancy : {occupancyPreleasedPercent}%</CardTitle>
                            <CardSubtitle></CardSubtitle>
                            <CardText color="danger">Vacant ({totalVacantNumber}): {vacantUnits.toString()},{preleasedUnits.toString()}</CardText>
                            <CardText>Preleased ({preleasedUnits.length}): {preleasedUnits.toString()}</CardText>
                            <CardText>Units On Hold: {number_of_hold_units}</CardText>
                            <CardText>Past Due Work Orders: {past_due_work_orders}, Work Orders: {due_work_orders}</CardText>
                            <CardText>Make Ready Work Orders: {makeready_work_orders}</CardText>                            
                            <ExpiringLeasesInfo rentRollInfoByDate={rentRollInfoByDate}/>
                            {/*<CardText>Pending Renew : {pendingRenewUnits.toString()}</CardText>*/}
                            <Progress multi>
                                <Progress bar color="success" value={totalUnits-vacantUnits.length-preleasedUnits.length}>Occupied</Progress>
                                <Progress bar color="info" value={preleasedUnits.length}>Preleased</Progress>
                                <Progress bar color="warning" value={pendingRenewUnits.length}>Pending</Progress>
                                <Progress bar color="danger" value={vacantUnits.length}>Vacant</Progress>
                            </Progress>
                        </Alert>
                    </CardBody>
                </Card>
            </div>
        );
    }
}

//export default PropertyCard;
export default withRouter (PropertyCard);