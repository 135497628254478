import React, { Component } from 'react'

class RentRollTableHeaderRow extends Component {
    constructor (props) {
      super(props)    
      this.state = {
        columnsItem:[]
      }
    }
  
    componentDidMount () {
      /*this.setState({
        columnsItem:columns
      })*/
    }
  
    render () {    
      const {columns} = this.props
      return (                  
          
            <tr>
              {columns.map((item, index) => {
                return <th colSpan="1" scope="col">
                  {item}            
                  </th>
              })}
            </tr>          
      )
    }
  }
  
  export default RentRollTableHeaderRow