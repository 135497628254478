import React from 'react';
import { Table } from 'reactstrap';
import Spinner from "reactstrap/es/Spinner";
import PropertyService from "../services/PropertyService";

export default class LatestLeases extends React.Component {

    buildRow(latestLeases) {
        var rowItem = []
        if(latestLeases.length === 0) {
            return rowItem
        }

        for (var index = 0; index < latestLeases.length; ++index) {
            rowItem.push(<tr>
                <th key={index + "_0"}scope="row">{latestLeases[index].UnitNumber}</th>
                <td key={index + "_1"}>{latestLeases[index].UnitType}</td>
                <td key={index + "_2"} className={latestLeases[index].CurrentRent < latestLeases[index].MarketRent ? 'table-danger' : ''} >{latestLeases[index].CurrentRent}</td>
                <td key={index + "_3"}>{latestLeases[index].MarketRent}</td>
                <td key={index + "_4"}>{latestLeases[index].LeaseStartDate}</td>
                <td key={index + "_5"}>{latestLeases[index].LeaseEndDate}</td>
            </tr>)
        }

        return rowItem
    }

    render() {
        const {currentRents} = this.props
        if (currentRents === undefined || currentRents.length === 0) {
            return (<div><Spinner type="grow" color="info" /></div>)
        }

        let propertyService = new PropertyService()
        let sortedByLeaseDate = propertyService.getLatestLeasesToCheckTheRentVsMarketRent(currentRents).slice(0, 10)
        let rows = this.buildRow(sortedByLeaseDate)
        return (
            <Table responsive>
                <thead>
                <tr>
                    <th>Unit #</th>
                    <th>Unit Type</th>
                    <th>Current Rent</th>
                    <th>Market Price</th>
                    <th>Lease Start</th>
                    <th>Lease End</th>
                </tr>
                </thead>
                <tbody>
                {rows}
                </tbody>
            </Table>
        );
    }
}