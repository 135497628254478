import React, {Component} from 'react';
import {ListGroupItem} from "reactstrap";
import PropertyService from "../services/PropertyService";
import LeaseExpirationComponent from "./leaseexpirations/LeaseExpirationsComponent"

class ExpiringLeasesInfo extends Component {
    constructor(props) {
        super(props)
        this.state = { collapse: false };
        this.toggle = this.toggle.bind(this);
    }

    toggle() {
        this.setState(state => ({ collapse: !state.collapse }));
    }

    componentDidMount() {

    }

    render() {
        const {rentRollInfoByDate} = this.props
        if (rentRollInfoByDate === undefined || Object.keys(rentRollInfoByDate).length === 0) {
            return
        }        
        const today = new Date()
        let propertyService = new PropertyService()

        
        let expiredLeases = this.populateExpiringLeaseInfo(today, rentRollInfoByDate, false)        

        let nextMonth = new Date(today.setMonth(today.getMonth()+1));
        let lastMonthDate = propertyService.getDateWithLastDayOfTheMonth(nextMonth)
        let expiringLeases1 = this.populateExpiringLeaseInfo(lastMonthDate, rentRollInfoByDate, true)        
        let nextMonthName = nextMonth.toLocaleString('default', { month: 'short' })

        let secondMonth = new Date(today.setMonth(today.getMonth()+1));        
        lastMonthDate = propertyService.getDateWithLastDayOfTheMonth(secondMonth)
        let expiringLeases2 = this.populateExpiringLeaseInfo(lastMonthDate, rentRollInfoByDate, true)
        let secondMonthName = secondMonth.toLocaleString('default', { month: 'short' })


        return (
            <div>                
                <LeaseExpirationComponent title={'Expired : ' + expiredLeases.length} expiringLeases={expiredLeases}></LeaseExpirationComponent>
                <LeaseExpirationComponent title={'Expiring in ' + nextMonthName + ' '+ nextMonth.getFullYear() +' : ' +expiringLeases1.length} expiringLeases={expiringLeases1}></LeaseExpirationComponent>
                <LeaseExpirationComponent title={'Expiring in ' + secondMonthName + ' ' +secondMonth.getFullYear() +' : ' +expiringLeases2.length} expiringLeases={expiringLeases2}></LeaseExpirationComponent>                        
            </div>
        );
    }

    compare(a, b) {
        // Use toUpperCase() to ignore character casing
        const weekA = a.weeks;
        const weekB = b.weeks;

        let comparison = 0;
        if (weekA > weekB) {
            comparison = -1;
        } else if (weekA < weekB) {
            comparison = 1;
        }
        return comparison;
    }

    populateExpiringLeaseInfo(dateToCheckAgainst, rentRollInfo, checkMonth) {
        if(rentRollInfo === undefined || rentRollInfo.length === 0) {
            return []
        }
        let propertyService = new PropertyService()        
        let expiringLeases = propertyService.getExpiringLeasesForMonth(dateToCheckAgainst, rentRollInfo[Object.keys(rentRollInfo)[0]], checkMonth)
        //return expiringLeases
        let groupItems = []
        expiringLeases.forEach(value => {            
            let expiration = ""
            if (value.LeaseStartDate != null) {
                expiration = "- Exp:" + value.LeaseEndDate
            }
            groupItems.push(<ListGroupItem className="justify-content-between">{value.Unit + '(' + value.UnitType + ') '} {' ' + expiration + ' '}</ListGroupItem>)
        });
        return groupItems
    }
}

export default ExpiringLeasesInfo;