import React from 'react';
import {
    Alert,
    Card,
    CardTitle,
    Nav,
    NavItem,
    NavLink,
    Row,
    TabContent,
    TabPane
} from 'reactstrap';
import {axiosInstance} from "../services/axiosservice";
import {FINANCIAL_URL, FORMAT_CSV, FORMAT_JSON} from "../Constants";
import {CsvToHtmlTable} from "react-csv-to-table";
import Spinner from "reactstrap/es/Spinner";
import classnames from 'classnames';
import ProfitAndLossGraph from "./Chart/lines";
import sample_data from "./Chart/sample_data.json";

const emptyDataSet = 'Loading data for this property'
const errorDataSet = 'Error occurred, can\'t display data for this property'
export default class Financials extends React.Component {

    constructor(props, context) {
        super(props, context);
        this.toggle = this.toggle.bind(this)
        this.state = {
            accountsToMonitorIncome:emptyDataSet,
            accountsToMonitorExpenses:emptyDataSet,
            accountsIncome:emptyDataSet,
            accountsExpenses:emptyDataSet,
            lastMonthAccountsToMonitorIncome:emptyDataSet,
            lastMonthAccountsToMonitorExpenses:emptyDataSet,
            propertyId:this.props.propertyId,
            activeTab: '1'
        }
    }

    toggle(tab) {
        if (this.state.activeTab !== tab) {
            this.setState({
                activeTab: tab
            });
        }
    }

    componentDidMount() {
        console.log("PropertyId Passed " + this.props.propertyId)
        let date = new Date()
        let lastMonthDate = new Date()
        lastMonthDate.setMonth(date.getMonth() - 1);
        this.loadData(this.props.propertyId, false)
        this.loadData(this.props.propertyId, true)
        this.loadDataForDate(this.props.propertyId, lastMonthDate)
    }

    loadDataForDate(propertyId, date) {
        if (propertyId === undefined) {
            return
        }
        let dateStr = this.formatDate(date)
        console.info("Start financial loadData data for " + propertyId + "for date " + dateStr)
        ///calculate?propertyId=d4414795-31f9-411f-b8d5-670c5fa81ec5&responseFormat=json&fullPL=True
        let url = FINANCIAL_URL + '/calculate?propertyId='+propertyId + '&responseFormat='+FORMAT_CSV + '&fullPL=false' + '&lastDate='+dateStr
        axiosInstance.get(url
        ) // JSON File Path
            .then( response => {
                console.info("Received financial loadData data for " + propertyId + " date " + dateStr)
                console.info("Income" + response.data["income"])
                console.info("Expenses " + response.data["expenses"])
                this.setState({
                    lastMonthAccountsToMonitorIncome: response.data["income"],
                    lastMonthAccountsToMonitorExpenses: response.data["expenses"]
                });

                console.info("Finished loading financial loadData data for " + propertyId)
            })
            .catch(error => {
                console.log(error.message);
                console.error("Error loading financial loadData data for " + propertyId, error)
                this.setState({
                    lastMonthAccountsToMonitorIncome: errorDataSet,
                    lastMonthAccountsToMonitorExpenses: errorDataSet
                });
            })
    }
    formatDate(value) {
        return value.getMonth()+1 + "/" + value.getDate() + "/" + value.getFullYear();
    }

    loadData(propertyId, fullPL) {
        if (propertyId === undefined) {
            return
        }
        console.info("Start financial loadData data for " + propertyId)
        ///calculate?propertyId=d4414795-31f9-411f-b8d5-670c5fa81ec5&responseFormat=json&fullPL=True
        let url = FINANCIAL_URL + '/calculate?propertyId='+propertyId + '&responseFormat='+FORMAT_CSV + '&fullPL='+fullPL
        axiosInstance.get(url
        ) // JSON File Path
            .then( response => {
                console.info("Received financial loadData data for " + propertyId + " fullPL " + fullPL)
                console.info("Income" + response.data["income"])
                console.info("Expenses " + response.data["expenses"])
                if(fullPL) {
                    this.setState({
                        accountsIncome: response.data["income"],
                        accountsExpenses: response.data["expenses"]
                    });
                }
                else {
                    this.setState({
                        accountsToMonitorIncome: response.data["income"],
                        accountsToMonitorExpenses: response.data["expenses"]
                    });
                }
                console.info("Finished loading financial loadData data for " + propertyId)
            })
            .catch(error => {
                console.log(error.message);
                console.error("Error loading financial loadData data for " + propertyId, error)
                if(fullPL) {
                    this.setState({
                        accountsToMonitorIncome: errorDataSet,
                        accountsToMonitorExpenses: errorDataSet
                    });
                }
                else {
                    this.setState({
                        accountsToMonitorIncome: errorDataSet,
                        accountsToMonitorExpenses: errorDataSet
                    });
                }
            })
    }

    render() {
        const date = new Date();
        const currentMonth = date.toLocaleString('default', { month: 'long' });
        var lastMonth = new Date();
        lastMonth.setMonth(date.getMonth() - 1);
        const previousMonth = lastMonth.toLocaleString('default', { month: 'long' });
        const {accountsToMonitorIncome} = this.state
        if (accountsToMonitorIncome === null) {
            return (<div><Spinner type="grow" color="info" /></div>)
        }
        return (
            <div>
                <Nav tabs>
                    <NavItem>
                        <NavLink
                            className={classnames({ active: this.state.activeTab === '1' })}
                            onClick={() => { this.toggle('1'); }}
                        >
                            Items to investigate for {currentMonth}
                        </NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink
                            className={classnames({ active: this.state.activeTab === '2' })}
                            onClick={() => { this.toggle('2'); }}
                        >
                            Items to investigate for {previousMonth}
                        </NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink
                            className={classnames({ active: this.state.activeTab === '3' })}
                            onClick={() => { this.toggle('3'); }}
                        >
                            Full P&L
                        </NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink
                            className={classnames({ active: this.state.activeTab === '4' })}
                            onClick={() => { this.toggle('4'); }}
                        >
                            Profit and Loss Graph
                        </NavLink>
                    </NavItem>
                </Nav>
                <TabContent activeTab={this.state.activeTab}>
                    <TabPane tabId="1">
                        <Row>
                            <Card body>
                                <CardTitle>Income accounts to monitor</CardTitle>
                                <Alert color="warning">
                                    <CsvToHtmlTable
                                        data={this.state.accountsToMonitorIncome}
                                        csvDelimiter=","
                                        tableClassName="table table-striped table-hover"
                                    />
                                </Alert>

                                <CardTitle>Expenses accounts to monitor</CardTitle>
                                <Alert color="warning">
                                    <CsvToHtmlTable
                                        data={this.state.accountsToMonitorExpenses}
                                        csvDelimiter=","
                                        tableClassName="table table-striped table-hover"
                                    />
                                </Alert>
                            </Card>
                        </Row>
                    </TabPane>
                    <TabPane tabId="2">
                        <Row>
                            <Card body>
                                <CardTitle>Income accounts to monitor</CardTitle>
                                <Alert color="warning">
                                    <CsvToHtmlTable
                                        data={this.state.lastMonthAccountsToMonitorIncome}
                                        csvDelimiter=","
                                        tableClassName="table table-striped table-hover"
                                    />
                                </Alert>

                                <CardTitle>Expenses accounts to monitor</CardTitle>
                                <Alert color="warning">
                                    <CsvToHtmlTable
                                        data={this.state.lastMonthAccountsToMonitorExpenses}
                                        csvDelimiter=","
                                        tableClassName="table table-striped table-hover"
                                    />
                                </Alert>
                            </Card>
                        </Row>
                    </TabPane>
                    <TabPane tabId="3">
                        <Row>
                            <Card body>
                                <CardTitle>Full P&L Income</CardTitle>
                                    <CsvToHtmlTable
                                        data={this.state.accountsIncome}
                                        csvDelimiter=","
                                        tableClassName="table table-striped table-hover"
                                    />
                            </Card>
                        </Row>
                        <Row>
                            <Card body>
                                <CardTitle>Full P&L Expenses</CardTitle>
                                    <CsvToHtmlTable
                                        data={this.state.accountsExpenses}
                                        csvDelimiter=","
                                        tableClassName="table table-striped table-hover"
                                    />
                            </Card>
                        </Row>
                    </TabPane>
                    <TabPane tabId="4">
                        <Row>
                            <ProfitAndLossGraph profit_and_loss={sample_data}/>
                        </Row>
                    </TabPane>
                </TabContent>
            </div>
        );
    }
}