import React, {Component} from 'react';
import { Switch, Route } from 'react-router-dom'
import Dashboard from "./Dashboard";
import Property from "./components/Property";

class Main extends Component {
    render() {
        return (
            <div>
                <Switch>
                    <Route exact path='/' component={Dashboard}/>
                    <Route path='/property/:propertyId' component={Property}/>
                    {/*<Route exact path='/property/cedarpoint' component={CedarPoint}/>
                    <Route exact path='/property/thornmanor' component={ThornManor}/>
                    <Route exact path='/property/runningbrook' component={Runningbrook}/>
                    <Route exact path='/property/kingswood' component={Kingswood}/>*/}
                </Switch>
            </div>
        );
    }
}

export default Main;