import {Col, Row} from "reactstrap";

import React, {Component} from 'react';

class Footer extends Component {
    render() {
        return (
            <div>
                <footer>
                    <Row>
                        <Col sm="12" md="12" lg="12">
                            <p className="align-self-center">&copy; 2019. WGI all right reserved"</p>
                        </Col>
                    </Row>
                </footer>
            </div>
        );
    }
}

export default Footer;