import React from 'react';
import {Card, CardTitle, Table} from 'reactstrap';
import LatestLeases from "./LatestLeases";
import Chart from "./Chart/chart";
import {axiosInstance} from "../services/axiosservice";
import {TRACKING_URL} from "../Constants";


export default class Analytics extends React.Component {

    constructor(props, context) {
        super(props, context);
        this.state = {
            currentRents:[],
            propertyId:this.props.propertyId
        }
    }


    componentDidMount() {
        console.log("PropertyId Passed " + this.props.propertyId)
        this.loadData(this.props.propertyId, 'currentrents')
    }

    loadData(propertyId, requestType) {
        if (propertyId === undefined) {
            return
        }
        console.info("Start loadData data for " + propertyId)
        let url = TRACKING_URL + '/daily?propertyId='+propertyId + '&requestType='+requestType
        axiosInstance.get(url
        ) // JSON File Path
            .then( response => {
                console.info("loadData Got data for " + propertyId + " " + requestType)
                this.setState({
                    currentRents: response.data
                });
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    render() {
        return (
            <Card body>
                <CardTitle>Latest Leases</CardTitle>
                <LatestLeases currentRents={this.state.currentRents}/>
                <CardTitle>Price By Unit Type</CardTitle>
                <Chart currentRents={this.state.currentRents}/>
            </Card>
        );
    }
}