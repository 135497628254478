import React, {Component} from 'react';
import {Button, CardText, Collapse, ListGroup, Container, Row, Col} from "reactstrap";

class LeaseExpirationComponent extends Component {
    constructor(props) {
        super(props)
        this.state = { collapse: false };
        this.toggle = this.toggle.bind(this);
    }

    toggle() {
        this.setState(state => ({ collapse: !state.collapse }));
    }

    componentDidMount() {

    }

    render() {
        const {title, expiringLeases} = this.props
        if (expiringLeases === undefined || Object.keys(expiringLeases).length === 0) {
            return <div></div>
        }        

        return (
            <div>
                <Container>
                    <Row>
                        <Col sm={8}><CardText>{title}</CardText></Col>
                        <Col><Button color="primary" onClick={this.toggle} style={{ marginBottom: '1rem' }}>Show</Button></Col>
                    </Row>
                </Container>                
                <Collapse isOpen={this.state.collapse}>
                    <ListGroup>
                        {expiringLeases}
                    </ListGroup>
                </Collapse>
            </div>
        );
    }
}

export default LeaseExpirationComponent;
