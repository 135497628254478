import axios from 'axios'

// baseURL Works in almost all cases, may need to be overridden a few times
let instance = axios.create({
    baseURL: 'https://s3-us-west-2.amazonaws.com/wgi.wehner'
})

instance.interceptors.response.use(function (response) {
    return response
}, function (error) {
    if (error.response && error.response.status === 401) {
        window.location.href = '/'
    }
    return Promise.reject(error)
})

instance.interceptors.request.use(function (config) {
    config.headers['Access-Control-Allow-Origin']='*'
    //config.headers['Access-Control-Allow-Headers']='Origin, X-Requested-With, Content-Type, Accept, X-Amz-Date,Authorization,X-Api-Key,X-Amz-Security-Token'
    config.headers['Content-Type']='application/json'
    return config
})
export const axiosInstance = instance
