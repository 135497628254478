import React, { Component } from 'react'
import RentRollTableHeaderRow from './RentRollTableHeaderRow'
import RentRollTableRow from './RentRollTableRow'
import CurrentVacantUnits from './CurrentVacantUnits'
//import axios from 'axios'
import { axiosInstance } from '../services/axiosservice'
import PropertyService from '../services/PropertyService'
import Spinner from "reactstrap/es/Spinner";
import {Col, Nav, NavItem, NavLink, Row, TabContent, TabPane, Table} from "reactstrap";
import classnames from 'classnames';
import Analytics from "./Analytics";
import Financials from "./Financials";
import {TRACKING_URL} from "../Constants";

class RentRollTable extends Component {
  constructor (props) {
    super(props)
    this.toggle = this.toggle.bind(this);
    this.state = {
      rentRoll:[],
      totalUnits:0,
      vacantUnits:[],
      preleasedUnits:[],
      pendingRenewUnits:[],
      activeTab: '1',
      paidInvoices:{}
    }
  }

  toggle(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab
      });
    }
  }
  /*componentDidMount () {
    this.setState({
      rentRoll:RentRoll
    })
  }*/
  getColumnsForRentRoll(rentRoll) {
    /**
     * {{key: 'date', label: 'Date'}, {key:unitName, label:unitName}, {key:unitName, label:unitName} ...}
     */
    var columns = []
    Object.keys(rentRoll).forEach(function(key, index) {
      columns.push(key)
    })
    let sortedColumn = columns.sort((a, b) => new Date(b) - new Date(a));
    return sortedColumn
  }

  getRentRollDatesRows(rentRoll) {
    /**
     * {"id":56480,"company":"Plasmox","name":{"first":"Catherine","last":"Welch"},"position":"Regional manager","address":"888 Himrod Street, Independence, Virgin Islands, 3588","phone":"+1 (968) 567-2395","mobile":"+1 (804) 414-3278","email":"catherine.welch@plasmox.name","registered":"Wednesday, August 27, 2014 11:46 AM"},
     */    
    var rows = []
    Object.keys(rentRoll).forEach(function(key, index) {
      var row = []
      row.push({'date':key})      
      var currentObj = rentRoll[key];      
      Object.entries(currentObj).forEach(([key, value]) => {
        var unit = {}
        unit[key.toString()]=value['Occupied']
        unit['Unit']=value        
        row.push(unit)        
      })
      rows.push(row)      
    })      
    return rows
  }

  getRentRollRowsByUnits(rentRoll, sortedColumn) {    
    var rows = []
    var colIndex = 0
    var vacantUnits = [] 
    var preleasedUnits = [] 
    var pendingRenewUnits = []
    var rowIndex = 0;
    sortedColumn.forEach(function(key, index) {
      rowIndex = 0
      var currentObj = rentRoll[key];      
      Object.entries(currentObj).forEach(([key, value]) => {      
        if (rows[rowIndex]===undefined) {
          rows.push([])
        }        
        if(colIndex === 0) {
          rows[rowIndex].push(value["Unit"])
        }      
        rows[rowIndex].push(value)                
        
        if(index === 0 && value["Occupied"] === false) {
          if(value["Status"] === 'Pending') {
            preleasedUnits.push(value["Unit"])
          }
          else if(value["Status"] === 'Pending Renewal') {
            //mark occupied
            pendingRenewUnits.push(value["Unit"])
            value["Occupied"] = true
          }
          else {
            vacantUnits.push(value["Unit"])
          }
        }
        rowIndex++
      })  
      colIndex++    
    })

    return {"vacantUnits" : vacantUnits,
      "preleasedUnits" : preleasedUnits,
      "pendingRenewUnits" : pendingRenewUnits,
      "totalUnits" : rowIndex,
      "rows":rows
    }
  }

  populateStatsWithRentRoll(rentRoll, sortedColumn) {
    let propertyService = new PropertyService()
    const result = propertyService.getRentRollRowsByUnits(rentRoll, sortedColumn)
    this.state.vacantUnits = result["vacantUnits"]
    this.state.preleasedUnits = result["preleasedUnits"]
    this.state.totalUnits = result["totalUnits"]
    this.state.pendingRenewUnits = result["pendingRenewUnits"]

    /*this.setState({rentRoll:rentRoll, vacantUnits:result["vacantUnits"],
      preleasedUnits:result["preleasedUnits"],
      pendingRenewUnits:result["pendingRenewUnits"],
      totalUnits:result["totalUnits"]
    })*/
    return [result["rows"], result["vacantUnits"]]
  }

  componentWillUpdate(nextProps, nextState, nextContext) {
    console.info("componentWillUpdate PropertyID " + nextProps.propertyId)
  }


  componentWillReceiveProps(nextProps, nextContext) {
    console.info("componentWillReceiveProps PropertyID " + nextProps.propertyId)
    this.setState({
      rentRoll: []
    });
    this.loadData(nextProps.propertyId)
  }

  lodPaidInvoices(propertyId) {
    let url = "https://my0y4ihkx1.execute-api.us-east-1.amazonaws.com/dev/paidinvoices?propertyId="+propertyId+"&propertyName=propertyId"

    axiosInstance.get(url
    ) // JSON File Path
        .then( response => {
          this.setState({
            paidInvoices: response.data
          });
        })
        .catch(error => {
          this.setState({
            paidInvoices: {}
          });
        }
        );

  }
  loadData(propertyId) {    
    let url = TRACKING_URL + 'daily?propertyId='+ propertyId + '&requestType=rentHistory'

    axiosInstance.get(url
    ) // JSON File Path
        .then( response => {
          this.setState({
            rentRoll: response.data
          });
        })
        .catch(function (error) {
          console.log(error);
        });
  }
  componentWillMount() {
    this.loadData(this.props.propertyId)
    //this.lodPaidInvoices(this.props.propertyId)
  }

  render () {    
    //var data = require('json!../data/89dc0034-1885-4e72-bab2-43bf778352ba.json');
    const {rentRoll} = this.state
    const {paidInvoices} = this.state
    if (rentRoll === 'undefined' || rentRoll.length === 0 || paidInvoices.length === 0) {
      return (<div><Spinner type="grow" color="info" /></div>)
    }
    var property = rentRoll['property']
    var rentRollInfo = rentRoll['dates']

    let propertyService = new PropertyService()

    //const {data} = this.state    
    var sortedColumn = propertyService.getColumnsForRentRoll(rentRollInfo)
    var sortedColumnShortDate = propertyService.getColumnsForRentRoll(rentRollInfo, true)
    var results = this.populateStatsWithRentRoll(rentRollInfo, sortedColumn)
    var calculatedRows = results[0]    
    //Add first column with "Unit" Caption
    //sortedColumn.unshift("Unit")
    sortedColumnShortDate.unshift("Unit")
    return (
        <div>
        <p>        
        For Property <b>{property["Name"]}</b>        
        </p>
          <Nav tabs>
            <NavItem>
              <NavLink
                  className={classnames({ active: this.state.activeTab === '1' })}
                  onClick={() => { this.toggle('1'); }}
              >
                Vacancy Overtime
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                  className={classnames({ active: this.state.activeTab === '4' })}
                  onClick={() => { this.toggle('4'); }}
              >
                Units Invoices
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                  className={classnames({ active: this.state.activeTab === '2' })}
                  onClick={() => { this.toggle('2'); }}
              >
                Property Statistics
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                  className={classnames({ active: this.state.activeTab === '3' })}
                  onClick={() => { this.toggle('3'); }}
              >
                Property Financials
              </NavLink>
            </NavItem>            
          </Nav>
          <TabContent activeTab={this.state.activeTab}>
            <TabPane tabId="1">
              <Row>
                <Col sm="12">
                  <CurrentVacantUnits vacantUnits = {this.state.vacantUnits} preleasedUnits = {this.state.preleasedUnits} totalUnits = {this.state.totalUnits} pendingRenewUnits={this.state.pendingRenewUnits}/>
                  <div data-name="rentRollTable">
                    <Table className="table table-bordered">
                      <tbody>
                      <RentRollTableHeaderRow columns={sortedColumnShortDate}/>
                      {calculatedRows.map((item, index) => {
                        return <RentRollTableRow
                            row={item}
                            key={`post-list-key ${index}`}
                            dates={sortedColumnShortDate}
                            paidInvoices = {paidInvoices}
                            didHandleRemove={this.handlePostRemove}
                            dataCallback={this.handleDataCallback}/>
                      })}
                      </tbody>
                    </Table>
                  </div>
                </Col>
              </Row>
            </TabPane>
            <TabPane tabId="2">
              <Row>
                <Col sm="9">
                  <Analytics propertyId={property["PropertyID"]}/>
                </Col>
              </Row>
            </TabPane>
            <TabPane tabId="3">
              <Row>
                <Col sm="9">
                  <Financials propertyId={property["PropertyID"]}/>
                </Col>
              </Row>
            </TabPane>
            <TabPane tabId="4">
              <Row>
                <Col sm="9">
                  <Financials propertyId={property["PropertyID"]}/>
                </Col>
              </Row>
            </TabPane>
          </TabContent>
        </div>
    )
  }
}

export default RentRollTable


