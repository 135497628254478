import React, {Component} from 'react';
import { Container, Row, Col } from 'reactstrap';
import PropertyCard from './PropertyCard'

class Dashboard extends Component {
    constructor() {
        super();
        this.state = {
            properties: [
                {
                    id:"1a98ad0f-a8fd-4653-9caf-9b6ef0c0200f",
                    name:"Cedar Point",
                    img:"https://www.cedarpointapartments.com/wp-content/uploads/2018/05/DSC_8583_OfficeEntry.jpg"
                },
                {
                    id:"ae3ecc74-b542-4c20-b62e-29af5a952939",
                    name:"Thorn Manor",
                    img:"https://thornmanorapartments.com/wp-content/uploads/2018/06/DSC_2815_EntrySign.jpg"

                },
                {
                    id:"f3c383cb-c37a-4e2d-899e-95de8b025e17",
                    name:"Running Brook",
                    img:"https://www.runningbrookhomes.com/wp-content/uploads/2018/09/42194699_246830626021446_3989704625740578816_n-1.jpg"
                },
                {
                    id:"a573acad-e9ea-49a1-a033-33e471b92c4d",
                    name:"Kingswood",
                    img:"https://www.kingswoodenton.com/wp-content/uploads/2018/10/kingswood-apartments-denton-tx-building-photo.jpg"
                },
                {
                    id:"679a439d-0880-4929-9914-29dc28470997",
                    name:"Creekwood",
                    img:"https://www.creekwoodesoto.com/wp-content/uploads/2019/07/28_Community.jpg"
                },
                {
                    id:"6d351558-9e93-4f1d-b421-e6b0513a7f54",
                    name:"Rolling Hills",
                    img:"https://www.rollinghillslancaster.com/gridmedia/img/footer-logo.svg"
                }
                ,
                {
                    id:"brook21",
                    name:"Brooklyn Place",
                    img:"https://resource.rentcafe.com/image/upload/q_auto,f_auto,c_limit,w_1200/s3/2/92185/other%20(5).jpg"
                }
                ,
                {
                    id:"bfield22",
                    name:"Brookefield",
                    img:"https://resource.rentcafe.com/image/upload/q_auto,f_auto,c_limit,w_190,h_85/s3/2/92185/brookefieldlogo.jpg"
                }
                ,
                {
                    id:"city22",
                    name:"City Pointe",
                    img:"https://resource.rentcafe.com/image/upload/w_299,h_150/q_auto,f_auto,c_limit,w_180,h_60/s3/2/92185/apartments-for-rent-evansville-city-point-logo-1-e1532109802582-299x150-235w.png"
                }
            ]
        }
    }


    render() {
        let propertyCards = this.state.properties.map(property => {
            return (
                <Col sm="4">
                    <PropertyCard key={property.id} property={property}/>
                </Col>
            )
        })
        return (
            <Container fluid>
                <Row>
                    {propertyCards}
                </Row>
            </Container>
        );
    }
}

export default Dashboard;
