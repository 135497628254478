import dateFormat from 'dateformat'

class PropertyService {

    getColumnsForRentRoll(rentRoll, shortDate=false) {


        /**
         * {{key: 'date', label: 'Date'}, {key:unitName, label:unitName}, {key:unitName, label:unitName} ...}
         */
        var columns = []
        Object.keys(rentRoll).forEach(function(key, index) {
            let toStore = key
            if(shortDate)
                toStore = dateFormat(new Date(key), "m/d/yy")
            columns.push(toStore)
        })
        let sortedColumn = columns.sort((a, b) => new Date(b) - new Date(a));
        return sortedColumn
    }

    addDays(date, days) {
        var result = new Date(date);
        result.setDate(result.getDate() + days);
        return result;
    }

    convertDictionaryToArray(dictionary) {
        let output = []
        for (const [key, value] of Object.entries(dictionary)) {
            value.key = key;
            output.push(value);
        }
        return output
    }

    getRentRollDatesRows(rentRoll) {
        /**
         * {"id":56480,"company":"Plasmox","name":{"first":"Catherine","last":"Welch"},"position":"Regional manager","address":"888 Himrod Street, Independence, Virgin Islands, 3588","phone":"+1 (968) 567-2395","mobile":"+1 (804) 414-3278","email":"catherine.welch@plasmox.name","registered":"Wednesday, August 27, 2014 11:46 AM"},
         */
        var rows = []
        Object.keys(rentRoll).forEach(function(key, index) {
            var row = []
            row.push({'date':key})
            var currentObj = rentRoll[key];
            Object.entries(currentObj).forEach(([key, value]) => {
                var unit = {}
                unit[key.toString()]=value['Occupied']
                unit['Unit']=value
                row.push(unit)
            })
            rows.push(row)
        })
        return rows
    }

    /**
     * Will return number of leases expiring in a give month
     * @param date for which to check leases
     * @param rentRollInfoByDate list of leases in a format of dictionary of the objects with key by unit number{
          "Unit": "111",
          "Occupied": true,
          "LeaseStartDate": "2020-01-17",
          "LeaseEndDate": "2021-01-31",
          "Rent": 1105,
          "Status": "Current",
          "UnitType": "2X1",
          "MarketRent": 1105
        }
     */
    /**
     * 
     * @param {*} date - date to check against
     * @param {*} rentRollInfoByDate list of units
     * @param {*} checkMonth chek that month matches. Used to check any expiring month or exact month of the date
     */
    getExpiringLeasesForMonth(date, rentRollInfoByDate, checkMonth) {
        let rentRollInfoByDateArray =  this.convertDictionaryToArray(rentRollInfoByDate)
        let expiringLeases = []
        for(let unit of rentRollInfoByDateArray) {
            if(unit.LeaseEndDate === null || unit.LeaseEndDate === undefined || unit.LeaseEndDate === '') {
                continue
            }
            let leaseEndDate = new Date(unit.LeaseEndDate)
            if (leaseEndDate < date ) {
                if(!checkMonth || leaseEndDate.getMonth() === date.getMonth()) {
                    expiringLeases.push(unit)
                }
            }
        }
        expiringLeases = expiringLeases.sort(function (p1, p2) {
            if (p1.LeaseEndDate === undefined || p2.LeaseEndDate === undefined) {
                return -100
            }
            return new Date(p2.LeaseEndDate) - new Date(p1.LeaseEndDate)
        });
        return expiringLeases
    }
    getRentRollRowsByUnits(rentRoll, sortedColumn) {
        var rows = []
        var colIndex = 0
        var vacantUnits = []
        var preleasedUnits = []
        var pendingRenewUnits = []
        var rowIndex = 0;
        sortedColumn.forEach(function(key, index) {
            rowIndex = 0
            var currentObj = rentRoll[key];
            Object.entries(currentObj).forEach(([key, value]) => {
                if (rows[rowIndex]===undefined) {
                    rows.push([])
                }
                if(colIndex === 0) {
                    rows[rowIndex].push(value["Unit"])
                }
                rows[rowIndex].push(value)

                if(index === 0 && value["Occupied"] === false) {
                    if(value["Status"] === 'Pending Renewal') {
                        //mark occupied
                        pendingRenewUnits.push(value["Unit"])
                        value["Occupied"] = true
                    }
                    else if(value["Status"].includes('Pending')) {
                        preleasedUnits.push(value["Unit"])
                    }
                    else {
                        vacantUnits.push(value["Unit"])
                    }
                }
                rowIndex++
            })
            colIndex++
        })

        return {"vacantUnits" : vacantUnits,
            "preleasedUnits" : preleasedUnits,
            "pendingRenewUnits" : pendingRenewUnits,
            "totalUnits" : rowIndex,
            "rows":rows
        }
    }

    getCurrentPricesGroupedByUnitType(currentRents) {
        /*
        {
      "PropertyID": "86b35b7f-78e0-4420-afdd-0a389c04fb9d",
      "UnitId": "bb2beb22-ec48-474f-b6bc-7bb3cff4e6d4",
      "UnitNumber": "111",
      "Building": "1",
      "Floor": "1",
      "StreetAddress": "300 W wintergreen Rd",
      "City": "Desoto",
      "State": "TX",
      "Zip": "75115",
      "UnitType": "2X1",
      "MarketRent": 1105,
      "CurrentRent": 1045.0
    }
         */
        //Group price by market, 10% or less cheaper, more then 10% off
        let unitTypeToPriceAndCount = new Map()
        for (let unit of currentRents) {
            let priceToCount = null
            if (unit.CurrentRent === undefined) {
                console.log("Unit " + unit.UnitNumber + " does not have a current rent")
                continue
            }
            let unitType =  unit.UnitType + "(" + unit.MarketRent + ")"
            if(!unitTypeToPriceAndCount.get(unitType)){
                priceToCount = new Map()
                unitTypeToPriceAndCount.set(unitType,priceToCount)
            }
            else {
                priceToCount = unitTypeToPriceAndCount.get(unitType)
            }
            //bucket
            let priceBucketbucket = this.calculatePriceBucket(unit.CurrentRent, unit.MarketRent)
            if (!priceToCount.get(priceBucketbucket)) {
                priceToCount.set(priceBucketbucket, 0)
            }
            priceToCount.set(priceBucketbucket, priceToCount.get(priceBucketbucket)+1)
        }
        return unitTypeToPriceAndCount
    }
    calculatePriceBucket(currentRent, marketRent) {
        if (currentRent >= marketRent) {
            return "Market"
        }
        else if((currentRent + (marketRent*.1)) >= marketRent) {
            return "90% or more of Market"
        }
        else {
            return "less then 90% of Market"
        }
    }
    convertUnitPriceInfoToGraphFormat(unitTypeToPriceAndCount) {
        /*
        {
        "unitType":"1X1",
        "860": 40,
        "875": 198,
        "920": 42,
        "900": 162,
        "865": 106
    }
         */
        let graphData = []
        let graphKeys = new Set()
        for (const [unitType, countersMap] of unitTypeToPriceAndCount.entries()) {
            let entry = {
                "unitType":unitType
            }
            for (const [price, counter] of countersMap.entries()) {
                entry[String(price)]=counter
                graphKeys.add(String(price))
            }
            graphData.push(entry)
        }
        return [graphData, Array.from(graphKeys)]
    }
    getLatestLeasesToCheckTheRentVsMarketRent(currentRents) {
        const onlyLeasedUnits = currentRents.filter(unit => unit.LeaseStartDate !== undefined);
        let sortByLeaseDate = onlyLeasedUnits.sort(function (p1, p2) {
            if (p1.LeaseStartDate === undefined || p2.LeaseStartDate === undefined) {
                return -100
            }
            return new Date(p2.LeaseStartDate) - new Date(p1.LeaseStartDate)
        });
        return sortByLeaseDate
    }

    getDateWithLastDayOfTheMonth = function(date){
        let lastDay = new Date(date.getFullYear(), date.getMonth()+1, 0).getDate();
        return new Date(date.getFullYear(), date.getMonth(), lastDay)
    }
}

export default PropertyService

