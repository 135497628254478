import React from 'react'
import { ResponsiveBar } from '@nivo/bar'
import config from './config'

import './chart.css'
import Spinner from "reactstrap/es/Spinner";
import PropertyService from "../../services/PropertyService";

class Chart extends React.Component {
    constructor(props, context) {
        super(props, context);
        this.state = {
            currentRents:[]
        }
    }

    render() {
        const {currentRents} = this.props
        if (currentRents === undefined || currentRents.length === 0) {
            return (<div><Spinner type="grow" color="info" /></div>)
        }

        let propertyService = new PropertyService()
        let currentPricesGroupedByUnitType = propertyService.getCurrentPricesGroupedByUnitType(currentRents);
        let convertUnitPriceInfoToGraphFormat = propertyService.convertUnitPriceInfoToGraphFormat(currentPricesGroupedByUnitType);
        let graphData = convertUnitPriceInfoToGraphFormat[0], graphKeys = convertUnitPriceInfoToGraphFormat[1]

        return (
            <div className="chart">
                <ResponsiveBar
                    data={graphData}
                    keys={graphKeys}
                    indexBy="unitType"
                    margin={config.margin}
                    padding={0.3}
                    colors={{ scheme: 'nivo' }}
                    defs={[
                        {
                            id: 'dots',
                            type: 'patternDots',
                            background: 'inherit',
                            color: '#38bcb2',
                            size: 4,
                            padding: 1,
                            stagger: true
                        },
                        {
                            id: 'lines',
                            type: 'patternLines',
                            background: 'inherit',
                            color: '#eed312',
                            rotation: -45,
                            lineWidth: 6,
                            spacing: 10
                        }
                    ]}
                    borderColor={{ from: 'color', modifiers: [ [ 'darker', 1.6 ] ] }}
                    axisTop={null}
                    axisRight={null}
                    axisBottom={config.axisBottom}
                    axisLeft={config.axisLeft}
                    labelSkipWidth={12}
                    labelSkipHeight={12}
                    labelTextColor={{ from: 'color', modifiers: [ [ 'darker', 1.6 ] ] }}
                    animate={true}
                    motionStiffness={90}
                    motionDamping={15}
                    legends={config.legends}
                />
            </div>
        )
    }
}

export default Chart