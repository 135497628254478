import React, { Component } from 'react'
import './App.css';
import TopNav from './Nav';
import Main from './Main'
import Footer from './Footer'
import { Container} from 'reactstrap'

class App extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            isOpen: false,
            tetherConfig: {
                target: '#tether',
                attachment: 'middle left',
                targetAttachment: 'middle right',
                classPrefix: 'bs-tether',
                classes: { element: 'popover', enabled: 'open' },
                constraints: [
                    { to: 'scrollParent', attachment: 'together none' },
                    { to: 'window', attachment: 'together none' }
                ]
            }
        };
        this.toggle = this.toggle.bind(this);
    }

    toggle() {
        this.setState({ isOpen: !this.state.isOpen });
    }

    render() {
        return (
            <div className="app">
                <Container fluid>
                    <TopNav/>
                    <Main/>
                    <Footer/>
                </Container>
            </div>
        );
    }
}

export default App;
