import React, { Component } from 'react'
import PropertyService from "../services/PropertyService";

class RentRollTableRow extends Component {  
    constructor (props) {      
      super(props)          
      this.state = {
      }
    }
  
    componentDidMount () {
      /*this.setState({
        columnsItem:columns
      })*/
    }    

    formatInvoice(invoice) {
      let invoices_description = ""
      return invoices_description.concat("Description:", invoice['description'], 
        "\nAmount:",invoice['amount'], "\nInvoice:", invoice['invoice'], "\nDate:", new Date(invoice['invoice_date']).toDateString()+'\n')
    }

    buildRow(row, dates, paidInvoices) {      
      var rowItem = []
      if(row.length === 0) {
        return rowItem
      }      
      
      let invoices_for_unit = []
      let invoices_description = ''      
      if(paidInvoices['invoices_by_location'] !== undefined && paidInvoices['invoices_by_location'][row[0]] !== undefined) {
        //get the invoices for this unit
        invoices_for_unit = paidInvoices['invoices_by_location'][row[0]]        
        invoices_description = "Number of Invoices " + invoices_for_unit.length
      }      

      for (var index = 0; index < row.length; ++index) {
        if(index === 0) {
            //row[1].UnitType from now on each unit will have unit type
            try {
                let invoice_total = 0
                let unit_color = ''
                let invoice_title = ''
                if(invoices_for_unit.length > 0) {
                  invoice_total = this.buildInvoicesTotal(invoices_for_unit)
                  if(invoice_total > 300) {
                    unit_color = 'table-warning'                    
                  }
                  invoice_title = '$' + Math.floor(invoice_total)
                }
                rowItem.push(<div rel="tooltip" title={invoices_description}><th colSpan="1" scope="row" key={index}
                className={unit_color}>{row[index] + '(' + row[1].UnitType + ')' + invoice_title}</th></div>)
            } catch (e) {
                rowItem.push(<th colSpan="1" scope="row" key={index}>{'index ' + index + '(UnknownType)'}</th>)
            }
        }
        else {
          let dateOfTheCurrentElement = dates[index]          
          let invoices_info = ''
          //if invoices where found try to find the unit          
          if(invoices_for_unit.length > 0) {
            invoices_info = this.buildInvoicesForTheUnit(invoices_for_unit, new Date(dateOfTheCurrentElement))            
            //invoice_indicator = invoices_info.length > 0 ? 'Inv' : ''
          }
          

          if(row[index].Occupied === false) {
            let tooltip = 'No prospects'
            if(row[index].Status.includes('Pending')) {
              tooltip = "Status: " + row[index].Status + ". Lease Starts " + row[index].LeaseStartDate
            }
            if(invoices_info.length > 0) {
              tooltip += '\n' + invoices_info
            }          
            rowItem.push(<td colSpan="1" key={index} className={invoices_info.length > 0 ? 'table-warning' : row[index].Status.includes('Pending') ? 'table-info' : 'table-danger'}>
            <div rel="tooltip" title={tooltip}>&nbsp;</div></td>)
          }
          else {
            //rowItem.push(<td colSpan="1" key={index}><div rel="tooltip" title={invoices_info}></div>{invoice_indicator}</td>)            
            rowItem.push(<td colSpan="1" key={index} className={invoices_info.length > 0 ? 'table-warning' : ''}>
            <div rel="tooltip" title={invoices_info}>&nbsp;</div></td>)
          }
          
        }
      }      
      return rowItem
    }
    buildInvoicesTotal(invoices_for_unit) {
      let total = 0
      for(let invoice of invoices_for_unit) {      
          //invoice falls into this bucket that we are adding          
          total += invoice['amount']
        }
        return total;
      }    
    buildInvoicesForTheUnit(invoices_for_unit, cell_date) {
      let propertyService = new PropertyService()
      //define a week betwene the date in the ceel + 7      
      let cell_date_week_end = propertyService.addDays(cell_date, 7)
      let invoicesInfo = ''
      let total = 0
      for(let invoice of invoices_for_unit) {
        let invoice_date = new Date(new Date(invoice['invoice_date']).toDateString())
        if(invoice_date < cell_date_week_end && invoice_date > cell_date) {
          //invoice falls into this bucket that we are adding
          invoicesInfo = invoicesInfo  + this.formatInvoice(invoice)
          total += invoice['amount']
        }
      }
      if(total > 0) {
        invoicesInfo += 'Total:' + total 
      }
      return invoicesInfo
    }
  
    render () {          
      const {row} = this.props   
      const {dates} = this.props
      const {paidInvoices} = this.props
      var rowItem = this.buildRow(row, dates, paidInvoices)
      return (
          <tr>
            {rowItem}
          </tr>
      )
    }
  }
  
  export default RentRollTableRow