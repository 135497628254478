import React, { Component } from 'react'

class CurrentVacantUnits extends Component {
    constructor (props) {
      super(props)    
      this.state = {
        columnsItem:[]
      }
    }
  
    componentDidMount () {
      /*this.setState({
        columnsItem:columns
      })*/
    }
  
    render () {    
      const {vacantUnits} = this.props 
      const {preleasedUnits} = this.props     
      const {pendingRenewUnits} = this.props
      let totalVacantNumber = vacantUnits.length+preleasedUnits.length
      let occupancyPercent = Math.trunc(100-(totalVacantNumber/this.props.totalUnits)*100)
        let totalVacant = preleasedUnits.length+vacantUnits.length;
        return (
        <div>          
          <div className={occupancyPercent >= 95 ? 'alert-success' : occupancyPercent >= 90 ? 'alert-warning': 'alert-danger'}>          
            <p>        
              Ocupancy is <b>{occupancyPercent}%</b>        
            </p>          
            <p>        
              Total of <b>{totalVacant}</b> vacant units out of <b>{this.props.totalUnits}</b>
            </p>          
            <p>        
              Vacant units ({totalVacant}): <b>{vacantUnits.toString()},{preleasedUnits.toString()}</b>
            </p>
            <p>        
              Prelease units ({preleasedUnits.length}) : <b>{preleasedUnits.toString()}</b>
            </p>
          </div>                         
        </div>
      )
    }
  }
  
  export default CurrentVacantUnits