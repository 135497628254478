import React, {Component} from 'react';
import {Badge, Button, Collapse, ListGroup, ListGroupItem, Container, Row, Col, CardTitle} from "reactstrap";

class VacantUnitsInfo extends Component {
    constructor(props) {
        super(props)
        this.state = { collapse: false };
        this.toggle = this.toggle.bind(this);
    }

    toggle() {
        this.setState(state => ({ collapse: !state.collapse }));
    }

    componentDidMount() {

    }

    render() {
        const {vacantHistory} = this.props
        const {occupancy} = this.props
        let groupItem = this.buildGroupItems(vacantHistory)        
        return (
            <div>
                <Container>
                    <Row>
                        <Col sm={8}><CardTitle>Occupancy : {occupancy}%</CardTitle></Col>
                        <Col><Button color="primary" onClick={this.toggle} style={{ marginBottom: '1rem' }}>Show</Button></Col>
                    </Row>
                </Container>                
                <Collapse isOpen={this.state.collapse}>
                    <ListGroup>
                        {groupItem}
                    </ListGroup>
                </Collapse>                
            </div>
        );
    }

    compare(a, b) {
        // Use toUpperCase() to ignore character casing
        const weekA = a.weeks;
        const weekB = b.weeks;

        let comparison = 0;
        if (weekA > weekB) {
            comparison = -1;
        } else if (weekA < weekB) {
            comparison = 1;
        }
        return comparison;
    }

    convertDictionaryToArray(vacantHistory) {
        let output = []
        for (const [key, value] of Object.entries(vacantHistory)) {
            value.unit = key;
            output.push(value);
        }
        return output
    }

    buildGroupItems(vacantHistory) {
        let groupItems = []
        if(vacantHistory === undefined || vacantHistory.length === 0) {
            return groupItems
        }

        let vacantHistoryArray = this.convertDictionaryToArray(vacantHistory)
        vacantHistoryArray.sort(this.compare);

        vacantHistoryArray.forEach(value => {
            let color = "secondary"
            let preleasedInfo = ""
            if (value.weeks > 2 && value.weeks <= 5) {
                color = "warning"
            }
            else if (value.weeks > 5) {
                color = "danger"
            }
            if (value.LeaseStartDate != null && value.LeaseStartDate.length > 0) {
                preleasedInfo = "Preleased:" + value.LeaseStartDate
            }
            groupItems.push(<ListGroupItem className="justify-content-between">{value.unit + '(' + value.UnitType + ') '}<Badge pill color={color} >{value.weeks + ' weeks '}</Badge> {' ' + preleasedInfo + ' '}</ListGroupItem>)
        });
        /*for (const [key, value] of Object.entries(vacantHistory)) {

        }*/

        return groupItems;
    }
}

export default VacantUnitsInfo;